<template>
  <v-dialog persistent v-model="dialog" width="550">

    <v-card>
      <v-card-title class="text-h5 pink darken-1 white--text">
        Lista archivos
      </v-card-title>

      <v-card-text class="pt-4">

        <v-form ref="form" v-model="valid" lazy-validation>

          <v-row>

            <v-col cols="12" md="8">
              <v-file-input @change="changeFiles" hide-details ref="fileinput" small dense outlined accept="image/*" multiple label="Archivos"></v-file-input>
            </v-col>

            <v-col cols="12" md="4">
              <v-btn @click="uploadFiles" :loading="isloading" rounded class="white--text" depressed color="green">Adjuntar</v-btn>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

                <template v-slot:item.path="{item}">

                  <a target="_blank" :href="item.path">
                    <v-icon small class="mr-2" color="orange">
                      mdi-cloud-download
                    </v-icon>
                  </a>

                </template>

              </v-data-table>
            </v-col>
          </v-row>



        </v-form>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn color="red lighten-1" text @click="closeDialog">
          Cerrar
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  import {
    aplicant_rules
  } from '../mixins/aplicant_rules'

  import {
    applicant_service
  } from '~/applicant_service'

  import {
    upload_service
  } from '~/upload_service'

  import {
    case_service
  } from '~/case_service'

  export default {
    mixins: [aplicant_rules],
    data() {
      return {
        dialog: false,

        items: [],

        data: {
          files: []
        },

        headers: [{
            text: 'Nombre de archivo',
            value: 'file_name',
          },
          {
            text: 'Descargar',
            value: 'path',
          },
          {
            text: '',
            value: 'actions',
          }
        ],

        item: {
          message: null
        },

        formData: new FormData(),
      }
    },

    created() {
      this.$parent.$on('open_file_dialog', data => {
        this.mapFiles(data.files)
        this.data = data
        this.dialog = true
      })
    },

    methods: {
      mapFiles(files) {
        this.items = files.map(x => {
          return {
            'file_name': x.substring(x.lastIndexOf('/') + 1).substring(25),
            'path': `${process.env.VUE_APP_AWS_URL}/${x}`
          }
        })
      },
      uploadFiles() {
        upload_service.upload_files('case', this.data.id, this.formData).then(res => {
          this.$toast.success('¡Archivos adjuntados con exito!')
          this.getCase(this.data.id)
          this.$emit('update_case', this.data.id)
          this.$refs.fileinput.reset()
          this.formData.delete('img')
        }, res => {
          this.$toast.warn('¡Error cargando archivos, intente nuevamente!')
        })
      },

      getCase(id) {
        case_service.get_case(this.data.id).then(res => {
          this.mapFiles(res.data.files)
          this.data = res.data
        }, res => {
          this.$toast.warn('¡Error cargando archivos, intente nuevamente!')
        })
      },

      changeFiles() {
        let archivos = event.target.files
        for (let archivo of archivos) {
          this.formData.append('img', archivo)
        }
      },

      closeDialog() {
        this.$emit('email_send')
        this.dialog = false
      }
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      aws_path() {
        return this.$store.getters.get_aws_path
      },
    }
  }
</script>