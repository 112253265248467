import ListaAplicantes from '../componentes/ListaAplicantes'
import ListaCasosAplicantes from '../componentes/ListaCasosAplicantes'
import CalendarioLegal from '../componentes/CalendarioLegal'
import ListaPagos from '../componentes/ListaPagos'
import ListaArchivos from '../componentes/ListaArchivos'

const routes = [
  ...route('/aplicantes', ListaAplicantes, {
    auth: true,
    require_consultant_package: true
  }),
  ...route('/casos-aplicante', ListaCasosAplicantes, {
    auth: true,
    require_consultant_package: true
  }),
  ...route('/calendario-legal', CalendarioLegal, {
    auth: true,
    require_consultant_package: true
  }),
  ...route('/lista-pagos', ListaPagos, {
    auth: true,
    require_consultant_package: true
  }),
  ...route('/lista-archivos', ListaArchivos, {
    auth: true,
    require_consultant_package: true
  }),
]

function route(path, component = Default, meta = {}) {
  return [{
    path,
    component,
    meta
  }]
}

export default routes