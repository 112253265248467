<template>
  <v-container>

    <v-row dense>
      <v-col cols="12" md="6">

        <loader v-if="isloading"></loader>

        <v-card>

          <v-toolbar flat color="blue accent-2" class="white--text">
            <v-btn to="/mis-posts" icon text>
              <v-icon small class="white--text">mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>
              <h3 class="font-weight-light">Actualizar perfil</h3>
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>

            <v-card>

              <v-img :src="perfil.image.includes('uploads') ? `${$awsPath}/${perfil.image}` : perfil.image" height="300px" dark>
                <v-row class="">
                  <v-card-title>
                    <v-btn dark icon>
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn dark icon class="mr-4">
                      <v-icon @click="editar = !editar">mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn dark icon>
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </v-card-title>

                </v-row>
              </v-img>

              <v-list two-line>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-account
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content v-show="!editar">
                    <v-list-item-title>{{perfil.name}} {{perfil.last_name}}</v-list-item-title>
                    <v-list-item-subtitle>Nombre</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content v-show="editar">
                    <v-text-field v-model="perfil.name" outlined small dense label="Nombre"></v-text-field>
                    <v-text-field v-model="perfil.last_name" outlined small dense label="Apellido"></v-text-field>
                  </v-list-item-content>

                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-phone
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content v-show="!editar">
                    <v-list-item-title>{{perfil.phone || 'N/A'}}</v-list-item-title>
                    <v-list-item-subtitle>Teléfono</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content v-show="editar">
                    <v-text-field v-model="perfil.phone" outlined small dense label="Teléfono"></v-text-field>
                  </v-list-item-content>

                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-email
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content v-show="!editar">
                    <v-list-item-title>{{perfil.email}}</v-list-item-title>
                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content v-show="editar">
                    <v-text-field v-model="perfil.email" outlined small dense label="Teléfono"></v-text-field>
                  </v-list-item-content>
                </v-list-item>


                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-map-marker
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content v-show="!editar">
                    <v-list-item-title>{{perfil.address.country}}</v-list-item-title>
                    <v-list-item-subtitle>{{perfil.address.state}}</v-list-item-subtitle>
                  </v-list-item-content>


                  <v-list-item-content v-show="editar">
                    <v-select :items="countries" outlined small dense v-model="perfil.address.country" label="País" item-text="name" item-value="name"></v-select>

                    <v-select :items="states" outlined small dense v-model="perfil.address.state" label="Estado" item-text="name" item-value="name"></v-select>

                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item v-show="editar">
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-folder-image
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-file-input @change="fileChange" small outlined dense accept="image/*" label="Imagen"></v-file-input>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-certificate
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-select v-model="account_category.categoryId" outlined small dense :items="categories" label="Categoria" item-text="name" item-value="id"></v-select>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-show="editar">
                  <v-list-item-icon>
                    <v-icon color="indigo">
                      mdi-content-save-settings
                    </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content class="pt-0">
                    <v-btn @click="updateAccount" class="white--text" color="pink">guardar</v-btn>
                  </v-list-item-content>
                </v-list-item>



              </v-list>
            </v-card>
          </v-card-text>

          <cancelar-sub></cancelar-sub>


        </v-card>


      </v-col>
    </v-row>


  </v-container>
</template>

<script>
  import {
    account_service
  } from '~/account_service'

  import {
    upload_service
  } from '~/upload_service'

  import {
    perfil_rules
  } from '../mixins/perfil_rules'

  import cancelarSub from './cancelarSub'

  import {
    states_service
  } from '~/states_service'

  import {
    country_service
  } from '~/country_service'

  import {
    categoria_service
  } from '~/categoria_service'

  export default {
    mixins: [perfil_rules],
    components: {
      cancelarSub
    },
    data() {
      return {
        editar: false,
        states: [],
        countries: [],
        categories: [],
        formData: new FormData(),
        account_category: {
          id: null,
          userId: null,
          categoryId: null
        },
        perfil: {
          name: null,
          last_name: null,
          email: null,
          phone: null,
          about: null,
          image: '',
          address: {
            state: null,
            country_code: null,
            country_flag: null,
            pha2_code: "FL",
            country: "United States",
            detect_country: null
          }
        }

      }
    },

    created() {
      this.getAccount(this.user.id)
      this.getStates()
      this.getCountries()
      this.getAccountCategory()
      this.getCategories('HANDYMAN_ROLE')
    },

    methods: {
      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categories = res.data
        }, res => {
          this.$toast.error('Error consultando categorias')
        })
      },
      getCountries() {
        this.countries = country_service.get_countries()
      },

      getStates() {
        states_service.get_states().then(res => {
          this.states = res.data
        }, res => {
          this.$toast.error('Error consultando estados')
        })
      },
      getAccount() {
        account_service.get_account(this.user.id).then(res => {
          this.perfil = res.data
        }, res => {
          this.$toast.error('Error consultando estados')
        })
      },

      getAccountCategory() {
        account_service.get_account_category().then(res => {
          if (res.data) {
            this.account_category = res.data
          }
        }, res => {
          this.$toast.error('Error obteniendo categoria')
        })
      },

      updateAccount() {
        account_service.update_account(this.perfil).then(res => {
          this.uploadFiles(res.data.id)
          this.editar = false
        }, res => {
          this.$toast.error('Error guardando perfil')
        })

        account_service.update_account_category(this.account_category).then(res => {}, res => {
          this.$toast.error('Error guardando categoria')
        })

      },

      uploadFiles(id) {
        if (!this.formData.has('img')) {
          this.getAccount(id)
          return this.$toast.sucs('Perfil actualizado con exito')
        }
        upload_service.upload_files('users', id, this.formData).then(res => {
          this.getAccount(id)
          this.$toast.sucs('Perfil actualizado con exito')
        }, res => {})
      },

      fileChange(file) {
        this.perfil.image = window.URL.createObjectURL(file)
        this.formData.append('img', file)
      }
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    }
  }
</script>