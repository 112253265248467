const modulo_aplicante = {
  strict: false,

  state: {
    aplicante: {
      name: null,
      email: null,
      phone: null,
      tags: []
    }
  },

  getters: {
    get_aplicante: state => state.aplicante,
  },

  mutations: {
    set_aplicante: (state, data) => state.aplicante = data,
  },

  actions: {
    setAplicante: (context, data) => context.commit('set_aplicante', data),
  }

}

export default modulo_aplicante