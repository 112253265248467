<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/aplicantes" icon text>
          <v-icon small class="white--text">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Casos</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <v-row dense>

          <v-col cols="12" md="5">
            <v-card class="pa-4 mb-4 elevation-0" shaped outlined>

              <v-row dense>
                <v-col cols="12">
                  <h3 class="font-weight-light grey--text text--darken-1">Nombre: {{ aplicante.name }}</h3>
                </v-col>

                <v-col cols="12">
                  <h3 class="font-weight-light grey--text text--darken-1">Email: {{ aplicante.email }}</h3>
                </v-col>

                <v-col cols="12">
                  <h3 class="font-weight-light grey--text text--darken-1">Teléfono: {{ aplicante.phone }}</h3>
                </v-col>

                <v-col cols="12" class="mt-2">
                  <v-btn @click="$emit('open_dialog', aplicante)" hide-details small dense :disabled="isloading" rounded depressed color="blue" class="white--text mr-3">
                    editar aplicante
                  </v-btn>
                </v-col>
              </v-row>

            </v-card>
          </v-col>


        </v-row>


        <v-row dense>
          <v-col cols="12" md="3">
            <v-btn @click="openCaseDialog" hide-details :disabled="isloading" rounded depressed color="pink darken-1" class="white--text mr-3">
              agregar nuevo caso
            </v-btn>
          </v-col>
        </v-row>

        <loader v-if="isloading"></loader>

        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">



              <template v-slot:item.uscisId="{item}">
                <span style="width: 300px;display: block;" v-if="item.uscisId">{{item.uscisId.actionCodeText}} </span>
                <span v-else>--</span>
              </template>

              <template v-slot:item.actions="{item}">


                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="$emit('open_edit_case_dialog', item)" small class="mr-2" color="blue" v-bind="attrs" v-on="on">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="$emit('open_timeline_dialog', item)" small class="mr-2" color="green" v-bind="attrs" v-on="on">
                      mdi-timeline-text-outline
                    </v-icon>
                  </template>
                  <span>Historial</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="$emit('open_file_dialog', item)" small class="mr-2" color="orange" v-bind="attrs" v-on="on">
                      mdi-file-account
                    </v-icon>
                  </template>
                  <span>Archivos</span>
                </v-tooltip>


              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

    <modal-case v-on:update_table="getCases($route.query.id)" :countries="countries" :categories="categories"></modal-case>

    <modal-aplicante v-on:update_table="refreshData($route.query.id)"></modal-aplicante>

    <modal-timeline></modal-timeline>

    <modal-archivos v-on:update_case="updateCase"></modal-archivos>

  </v-container>
</template>

<script>
  import {
    applicant_service
  } from '~/applicant_service'

  import {
    country_service
  } from '~/country_service'

  import {
    case_category_service
  } from '~/case_category_service'

  import modalAplicante from './modalAplicante'

  import modalTimeline from './modalTimeline'

  import modalCase from './modalCase'

  import modalArchivos from './modalArchivos'

  import debounce from 'lodash/debounce'

  import {
    case_service
  } from '~/case_service'

  export default {
    components: {
      modalAplicante,
      modalCase,
      modalTimeline,
      modalArchivos
    },
    data() {
      return {
        search: {
          search: ""
        },

        countries: [],

        categories: [],

        countries: [],

        items: [],

        aplicante: {
          name: null,
          email: null,
          phone: null,
          tags: []
        },

        headers: [{
            text: 'Categoria',
            value: 'caseCategoryId.name',
          },
          {
            text: 'Nro caso',
            value: 'receiptNumber',
          },
          {
            text: 'Tipo',
            value: 'type',
          },
          {
            text: 'Status USCIS',
            value: 'uscisId',
            width: 220
          },
          {
            text: 'Acciones',
            value: 'actions',
          },
        ]
      }
    },

    created() {
      this.getCases(this.$route.query.id)
      this.getApplicant(this.$route.query.id)
      this.getCaseCategories()
      this.getCountries()
    },

    methods: {
      updateCase(id) {
        this.getCases(this.$route.query.id)
      },

      refreshData(id) {
        this.getCases(id)
        this.getCaseCategories()
      },

      getCaseCategories(data = null) {
        case_category_service.get_my_case_categories().then(res => {
          this.categories = res.data
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },

      getApplicant(id) {
        applicant_service.get_applican_by_id(id).then(res => {
          this.aplicante = res.data
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },


      getCountries() {
        this.countries = country_service.get_countries()
      },

      openCaseDialog() {
        this.$emit('open_case_dialog', {
          id: this.$route.query.id
        })
      },

      getCases(id) {
        applicant_service.get_cases_by_aplicant(id).then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },

    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>