export const aplicant_rules = {
  data() {
    return {
      valid: true,
      required_rule: [
        v => !!v || 'Campo es requerido',
      ],
      email_rules: [
        v => !!v || 'Requerido',
        v => /.+@.+\..+/.test(v) || 'E-mail debe ser valido',
      ],

    }
  },
}