<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/" icon text>
          <v-icon small class="white--text">mdi-home</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Aplicantes</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <v-row dense>
          <v-col cols="12" md="3">
            <v-text-field outlined small dense label="Buscar"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn @click="$emit('open_dialog')" hide-details :disabled="isloading" rounded depressed color="pink darken-1" class="white--text mr-3">
              crear aplicante
            </v-btn>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="8">
            <v-btn to="/calendario-legal" hide-details :disabled="isloading" rounded depressed color="orange" class="white--text mr-3">
              Calendario
              <v-icon right dark>
                mdi-calendar
              </v-icon>
            </v-btn>
            <v-btn to="/lista-archivos" hide-details :disabled="isloading" rounded depressed color="green" class="white--text mr-3">
              Archivos
              <v-icon right dark>
                mdi-cloud-upload
              </v-icon>
            </v-btn>
            <v-btn to="/lista-pagos" hide-details :disabled="isloading" rounded depressed color="blue" class="white--text mr-3">
              Pagos
              <v-icon right dark>
                mdi-reminder
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <loader v-if="isloading"></loader>

        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">


              <template v-slot:item.tags="{item}">
                <v-chip v-for="tag in item.tags" color="green" small dense class="white--text ma-1">{{ tag }}</v-chip>
              </template>

              <template v-slot:item.actions="{item}">


                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="editItem(item)" small class="mr-2" color="blue" v-bind="attrs" v-on="on">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="openCaseDialog(item)" small class="mr-2" color="orange" v-bind="attrs" v-on="on">
                      mdi-file-account
                    </v-icon>
                  </template>
                  <span>Crear solicitud / Caso</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="showCases(item)" small class="mr-2" color="green">
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Ver casos</span>
                </v-tooltip>

              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

    <modal-aplicante v-on:update_table="getApplicants"></modal-aplicante>

    <modal-case :countries="countries" :categories="categories"></modal-case>

  </v-container>
</template>

<script>
  import {
    applicant_service
  } from '~/applicant_service'

  import {
    country_service
  } from '~/country_service'

  import {
    case_category_service
  } from '~/case_category_service'

  import modalAplicante from './modalAplicante'

  import modalCase from './modalCase'

  import debounce from 'lodash/debounce'

  export default {
    components: {
      modalAplicante,
      modalCase
    },
    data() {
      return {
        search: {
          search: ""
        },

        categories: [],

        countries: [],

        items: [],

        headers: [{
            text: 'Nombre',
            value: 'name',
          },
          {
            text: 'Email',
            value: 'email',
          },
          {
            text: 'Teléfono',
            value: 'phone',
          },
          {
            text: 'Tags',
            value: 'tags',
            width: 220
          },
          {
            text: 'Acciones',
            value: 'actions',
          },
        ]
      }
    },

    created() {
      this.getApplicants()
      this.getCaseCategories()
      this.getCountries()
    },

    methods: {
      showCases(item) {
        this.$store.dispatch('setAplicante', item)
        this.$router.push({
          path: 'casos-aplicante',
          query: {
            id: item.id
          }
        })

      },

      getCountries() {
        this.countries = country_service.get_countries()
      },

      getApplicants(data = null) {
        applicant_service.get_applicants().then(res => {
          this.items = res.data.reverse()
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },

      getCaseCategories(data = null) {
        case_category_service.get_my_case_categories().then(res => {
          this.categories = res.data
        }, res => {
          this.$toast.error('Error consultando posts')
        })
      },

      editItem(item) {
        this.$emit('open_dialog', item)
      },

      get_color(status) {
        return this.colors[status]
      },

      openCaseDialog(item) {
        this.$emit('open_case_dialog', item)
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>