import { render, staticRenderFns } from "./ListaCasosAplicantes.vue?vue&type=template&id=00774f14"
import script from "./ListaCasosAplicantes.vue?vue&type=script&lang=js"
export * from "./ListaCasosAplicantes.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports