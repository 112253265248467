<template>
  <v-dialog persistent v-model="dialog" width="500">

    <v-card>
      <v-card-title class="text-h5 pink darken-1 white--text">
        Editar / Guardar
      </v-card-title>

      <v-card-text class="pt-4">

        <v-form ref="form" v-model="valid" lazy-validation>

          <v-row dense>
            <v-col cols="12">
              <v-select v-model="item.type" small outlined dense :items="types" label="Tipo"></v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-combobox v-model="item.caseCategoryId" small outlined dense :items="categories" label="Categoria" item-text="name" item-value="id"></v-combobox>
            </v-col>
          </v-row>

          <v-row v-if="item.type == 'NVC'" dense>
            <v-col cols="12">
              <v-select v-model="item.nvcType" small outlined dense :items="nvc_types" label="Tipo de visa" item-text="label" item-value="value"></v-select>
            </v-col>
          </v-row>

          <v-row v-if="item.type == 'NVC'" dense>
            <v-col cols="12">
              <v-select v-model="item.location" small outlined dense :items="countries" label="País" item-text="name" item-value="name"></v-select>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field v-model="item.receiptNumber" small dense outlined label="Nro de caso"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense v-if="item.type == 'NVC' && item.nvcType == 'NO-INMIGRANT'">
            <v-col cols="12">
              <v-text-field v-model="item.passportNumber" small dense outlined label="Nro de pasaporte"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-textarea v-model="item.details" small dense outlined label="Detalles"></v-textarea>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-combobox small-chips v-model="item.tags" label="Tags" multiple chips></v-combobox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-btn :loading="isloading" @click="updateOrCreate" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
            </v-col>
          </v-row>


        </v-form>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn color="red lighten-1" text @click="closeDialog">
          Cerrar
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  import {
    aplicant_rules
  } from '../mixins/aplicant_rules'

  import {
    case_service
  } from '~/case_service'

  export default {
    props: ['categories', 'countries'],

    mixins: [aplicant_rules],
    data() {
      return {
        dialog: false,

        types: ['USCIS', 'DOL', 'NVC'],

        nvc_types: [{
          label: 'Visa de inmigracion',
          value: 'INMIGRANT'
        }, {
          label: 'Visa sin inmigracion',
          value: 'NO-INMIGRANT'
        }],

        item: {
          type: 'USCIS',
          applicantId: null,
          caseCategoryId: null,
          uscisId: null,
          nvcType: null,
          location: null,
          dolCaseNumber: null,
          passportNumber: null,
          receiptNumber: "EAC9999103506",
          details: null,
          tags: []
        }
      }
    },

    created() {
      this.$parent.$on('open_case_dialog', data => {
        if (data) {
          this.item.applicantId = data.id
        }
        this.dialog = true
      })
      this.$parent.$on('open_edit_case_dialog', data => {
        if (data) {
          this.item = JSON.parse(JSON.stringify(data))
          this.item.applicantId = this.$route.query.id
        }
        this.dialog = true
      })
    },

    methods: {
      updateOrCreate() {
        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Error de validando formulario')
        }
        this.item.id ? this.update() : this.save()
      },

      update() {
        case_service.update_case(this.item.id, this.item).then(res => {
          this.$toast.sucs('Caso actualizado con exito!')
          this.closeDialog()
          this.$emit('update_table')
        }, res => {
          this.$toast.error('Error guardando caso')
        })
      },

      save() {
        case_service.save_case(this.item).then(res => {
          this.$toast.sucs('Caso guardado con exito!')
          this.closeDialog()
          this.$emit('update_table')
        }, res => {
          this.$toast.error('Error guardando caso')
        })
      },

      closeDialog() {
        this.$refs.form.resetValidation()

        this.item = {
          type: 'USCIS',
          applicantId: null,
          caseCategoryId: null,
          uscisId: null,
          nvcType: null,
          location: null,
          dolCaseNumber: null,
          passportNumber: null,
          receiptNumber: "EAC9999103506",
          details: null
        }
        this.dialog = false
      }


    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>