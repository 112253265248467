import axios from 'axios'

export const case_service = {

  async get_case(id) {
    return axios.get(`get-case-by-id/${id}`)
  },

  async get_cases_by_user() {
    return axios.get(`get-cases-by-user`)
  },

  async get_cases_files() {
    return axios.get(`get-file-cases-by-user`)
  },

  async save_case(data) {
    return axios.post(`save-applicant-case`, data)
  },

  async update_case(id, data) {
    return axios.put(`update-applicant-case/${id}`, data)
  },
}