<template>
  <div class="">
    <v-dialog persistent v-model="dialog" width="560">

      <v-card>
        <v-card-title class="text-h5 pink darken-1 white--text">
          Historial
        </v-card-title>

        <v-card-text class="pt-4">
          <v-timeline dense clipped>
            <v-timeline-item fill-dot class="white--text mb-12" color="orange" large>

              <template v-slot:icon>
                <span>+</span>
              </template>

              <v-text-field small dense outlined v-model="timeline.details" hide-details label="Añadir item" solo @keydown.enter="comment">
                <template v-slot:append>
                  <v-btn class="mx-0" depressed @click="comment">
                    agregar
                  </v-btn>
                </template>
              </v-text-field>

              <div class="d-flex">
                <v-checkbox hide-details v-model="timeline.status" label="Completado" class="mr-4"></v-checkbox>
                <v-checkbox hide-details v-model="timeline.reminder" label="Enviar recordatorio?"></v-checkbox>
              </div>

            </v-timeline-item>

            <v-slide-x-transition group>

              <template v-for="(event, i) in applicant_case.timeline">

                <v-timeline-item :key="i" class="mb-4" :class="{'pb-4' : event.reminder}" :color="event.status ? 'green' : 'grey'" small>

                  <v-row justify="space-between">
                    <v-col cols="7" v-text="event.details"></v-col>
                    <v-col class="text-right" cols="5">
                      <span>{{event.created_at | format_date}}</span>

                      <span>
                        <v-icon small class="ml-2" @click="deleteItem(event)" color="red lighten-1">
                          mdi-trash-can
                        </v-icon>
                      </span>
                    </v-col>
                  </v-row>

                  <v-row dense v-if="event.reminder">
                    <v-col cols="7">
                      <v-btn @click="openDialog" small dense class="mx-0 mt-2">
                        Enviar Email
                      </v-btn>
                    </v-col>

                  </v-row>

                </v-timeline-item>


              </template>


            </v-slide-x-transition>

            <!-- <v-timeline-item class="mb-6" hide-dot>
              <span>HOY</span>
            </v-timeline-item> -->
          </v-timeline>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>

          <v-btn @click="update" :loading="isloading" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>

          <v-spacer></v-spacer>

          <v-btn color="red lighten-1" text @click="closeDialog">
            Cerrar
          </v-btn>


        </v-card-actions>

      </v-card>




    </v-dialog>

    <modal-mensaje v-on:email_send="EmailSend"></modal-mensaje>

  </div>

</template>

<script type="text/javascript">
  import {
    aplicant_rules
  } from '../mixins/aplicant_rules'

  import {
    applicant_service
  } from '~/applicant_service'

  import {
    case_service
  } from '~/case_service'

  import moment from 'moment'

  import modalMensaje from './modalMensaje'

  export default {
    mixins: [aplicant_rules],
    components: {
      modalMensaje
    },
    data() {
      return {
        dialog: false,

        applicant_case: {
          timeline: []
        },

        timeline: {
          details: null,
          created_at: new Date(),
          hour: null,
          reminder: false,
          status: false
        }
      }
    },

    created() {
      this.$parent.$on('open_timeline_dialog', data => {
        if (data) {
          this.applicant_case = JSON.parse(JSON.stringify(data))
          this.applicant_case.applicantId = this.applicant_case.applicantId.id
          if (!data.timeline) {
            this.applicant_case.timeline = []
          }
        }
        this.dialog = true
      })
    },

    methods: {
      openDialog() {
        console.log('open');
        this.$emit('open_message_dialog')
      },

      EmailSend() {
        this.$toast.sucs('Email enviado con exito!')
      },

      deleteItem(event) {
        let i = this.applicant_case.timeline.indexOf(event)
        this.applicant_case.timeline.splice(i, 1)
      },

      update() {
        case_service.update_case(this.applicant_case.id, this.applicant_case).then(res => {
          this.$toast.sucs('Historial actualizado con exito!')
          this.applicant_case = res.data
        }, res => {
          this.$toast.error('Error actualizando historial')
        })
      },
      comment() {
        const time = (new Date()).toTimeString().substring(0, 5)
        this.timeline.created_at = new Date().toISOString().substring(0, 10)
        this.timeline.time = time
        this.applicant_case.timeline.unshift(this.timeline)
        this.timeline = {
          details: null,
          created_at: new Date(),
          hour: null,
          reminder: false,
          status: false
        }
      },
      closeDialog() {
        this.dialog = false
      }
    },

    filters: {
      format_date(db_date) {
        return moment(db_date).format('DD-MM-YYYY')
      },
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
      /*timeline() {
        return this.applicant_case.timeline.slice().reverse()
      },*/
    }
  }
</script>