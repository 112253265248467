import axios from 'axios'

export const applicant_service = {

  async get_applicants() {
    return axios.get(`get-my-applicants`)
  },

  async get_applican_by_id(id) {
    return axios.get(`get-applicant-by-id/${id}`)
  },

  async save_applicant(data) {
    return axios.post(`save-applicant`, data)
  },

  async update_applicant(id, data) {
    return axios.put(`update-applicant/${id}`, data)
  },

  async get_cases_by_aplicant(id) {
    return axios.get(`get-cases-by-applicant-id/${id}`)
  },

}