<template>
  <v-dialog persistent v-model="dialog" width="450">

    <v-card>
      <v-card-title class="text-h5 pink darken-1 white--text">
        Contenido email
      </v-card-title>

      <v-card-text class="pt-4">

        <v-form ref="form" v-model="valid" lazy-validation>

          <v-row dense>
            <v-col cols="12">
              <v-textarea v-model="item.message" small dense outlined label="Mensaje"></v-textarea>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-btn @click="closeDialog" :loading="isloading" rounded class="white--text" depressed color="green">enviar email</v-btn>
            </v-col>
          </v-row>

        </v-form>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn color="red lighten-1" text @click="closeDialog">
          Cerrar
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  import {
    aplicant_rules
  } from '../mixins/aplicant_rules'

  import {
    applicant_service
  } from '~/applicant_service'

  export default {
    mixins: [aplicant_rules],
    data() {
      return {
        dialog: false,

        item: {
          message: null
        }
      }
    },

    created() {
      this.$parent.$on('open_message_dialog', data => {
        this.dialog = true
      })
    },

    methods: {
      closeDialog() {
        this.$emit('email_send')
        this.dialog = false
      }
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>