<template>
  <v-container>
    <v-row color="grey lighten-4">
      <!--<v-col v-for="item in computedheaders" :key="item.text" cols="12" md="4">

        <v-hover v-slot="{ hover }" open-delay="20">
          <v-card shaped :to="item.path" :elevation="hover ? 3 : 1" :class="{ 'on-hover': hover }" class="mx-auto justify-center">

            <v-card-title class="justify-center">
              <span class="title font-weight-light grey--text text--darken-1 text-uppercase">{{item.text}}</span>
            </v-card-title>
            <v-card-text class="headline font-weight-bold justify-center text-center">
              <v-icon color="pink" large center justify-center class="justify-center">
                {{item.icon}}
              </v-icon>
            </v-card-text>
          </v-card>
        </v-hover>

      </v-col>-->

      <v-col cols="12" md="4">
        <v-card shaped color="blue" dark>
          <v-card-title class="justify-center text-center">
            <v-icon large left>
              mdi-checkbox-multiple-marked-circle
            </v-icon>
          </v-card-title>
          <v-card-text class="justify-center text-center">
            <span class="title font-weight-medium white--text">
              Servicios creados: <strong>1</strong> </span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card shaped color="green" dark>
          <v-card-title class="justify-center text-center">
            <v-icon large left>
              mdi-calendar
            </v-icon>
          </v-card-title>
          <v-card-text class="justify-center text-center">
            <span class="title font-weight-medium white--text">
              Citas: <strong>6</strong> </span>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card shaped color="amber darken-1" dark>
          <v-card-title class="justify-center text-center">
            <v-icon large left>
              mdi-chat-processing
            </v-icon>
          </v-card-title>
          <v-card-text class="justify-center text-center">
            <span class="title font-weight-medium white--text">
              Chats: <strong>6</strong> </span>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import {
    menu_items_mixin
  } from '../../global_mixins/menu_items_mixin'

  export default {
    mixins: [menu_items_mixin]
  }
</script>

<style>
  .my-card {
    transition: all 0.5s;
  }

  .v-card {
    transition: all 0.5s;
  }

  .my-card:hover .v-card {
    background-color: #2196F3 !important;
  }

  .my-card:hover .v-card .v-card__text i {
    color: #fff !important;
  }

  .my-card:hover .v-card .v-card__title span {
    color: #fff !important;
  }
</style>