import modulo_estado from './modulos/modulo_estado'
import modulo_token from './modulos/modulo_token'
import modulo_categoria from './modulos/modulo_categoria'
import modulo_package from './modulos/modulo_package'
import modulo_notificaciones from './modulos/modulo_notificaciones'
import modulo_aplicante from './modulos/modulo_aplicante'

import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: false,

  modules: {
    estado: modulo_estado,
    token: modulo_token,
    categoria: modulo_categoria,
    pack: modulo_package,
    notificaciones: modulo_notificaciones,
    aplicante: modulo_aplicante
  },

})

export default store;