<template>
  <v-container>

    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/aplicantes" icon text>
          <v-icon small class="white--text">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Lista Archivos</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <v-row dense>

          <v-col cols="12" md="4">
            <v-select v-model="case_id" :items="cases" hide-details outlined small dense label="Nro. Caso" item-text="receiptNumber" item-value="id"></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-file-input multiple hide-details ref="fileinput" small dense outlined @change="changeFiles" label="Archivos"></v-file-input>
          </v-col>

          <v-col cols="12" md="4">
            <v-btn @click="uploadFiles" rounded class="white--text" depressed color="blue accent-2">guardar</v-btn>
          </v-col>

        </v-row>


        <v-row dense class="mt-6">
          <v-col cols="12" md="4">
            <v-text-field color="green" hide-details v-model="search" outlined small dense label="Busqueda rápida"></v-text-field>
          </v-col>
        </v-row>


        <loader v-if="isloading"></loader>

        <v-row dense>
          <v-col cols="12">
            <v-data-table :search="search" :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">

              <template v-slot:item.file_path="{item}">

                <a class="blue--text" target="_blank" :href="item.file_path">
                  {{item.file_path.substring(item.file_path.lastIndexOf('/') + 1).substring(25)}}
                </a>

              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

  </v-container>
</template>

<script>
  import {
    categoria_service
  } from '~/categoria_service'

  import {
    case_service
  } from '~/case_service'

  import {
    upload_service
  } from '~/upload_service'

  export default {

    data() {
      return {
        search: null,

        formData: new FormData(),

        items: [],

        cases: [],

        case_id: null,

        headers: [{
            text: 'Aplicante',
            value: 'applicant_name',
          },
          {
            text: 'Nro de caso',
            value: 'receiptNumber',
          },
          {
            text: 'Archivo',
            value: 'file_path',
          }
        ],
      }
    },

    created() {
      this.getFiles()
      this.getCasesByUser()
    },

    methods: {

      getCasesByUser() {
        case_service.get_cases_by_user().then(res => {
          this.cases = res.data
        }, res => {
          this.$toast.error('Error consultando archivos')
        })
      },

      getFiles() {
        case_service.get_cases_files().then(res => {
          this.items = res.data
        }, res => {
          this.$toast.error('Error consultando archivos')
        })
      },

      changeFiles() {
        let archivos = event.target.files
        for (let archivo of archivos) {
          this.formData.append('img', archivo)
        }
      },

      uploadFiles() {
        upload_service.upload_files('case', this.case_id, this.formData).then(res => {
          this.$toast.success('¡Archivos adjuntados con exito!')
          this.formData.delete('img')
          this.$refs.fileinput.reset()
          this.getFiles()
        }, res => {
          this.$toast.warn('¡Error cargando archivos, intente nuevamente!')
        })
      },
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      }
    }
  }
</script>