<template>
  <v-dialog persistent v-model="dialog" width="400">


    <v-card>
      <v-card-title class="text-h5 pink darken-1 white--text">
        Crear / Editar evento
      </v-card-title>

      <v-card-text class="pt-6">
        <v-row dense>

          <v-col cols="12" md="6">

            <v-menu ref="menu_clock" v-model="menu2" :close-on-content-click="false" :nudge-right="40" :return-value.sync="item.time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined small dense v-model="item.time" label="Hora" prepend-inner-icon="mdi-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-time-picker v-if="menu2" v-model="item.time" full-width @click:minute="$refs.menu_clock.save(item.time)"></v-time-picker>
            </v-menu>

          </v-col>


          <v-col cols="12" md="6">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="item.start" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined small dense v-model="item.start" label="Fecha" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="item.start" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(item.start)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12">
            <v-textarea v-model="item.details" outlined label="Detalles"></v-textarea>
          </v-col>

          <v-col cols="12">
            <v-file-input hide-details label="Archivo" prepend-icon="" prepend-inner-icon="mdi-cloud-upload" truncate-length="15" outlined small dense></v-file-input>
          </v-col>

          <v-col cols="12">
            <v-checkbox v-model="item.reminder" label="Enviar recordatorio"></v-checkbox>
          </v-col>

          <v-col cols="12" class="mt-1">
            <v-select v-model="item.aplicante" outlined small dense :items="applicants" label="Asociar aplicante" item-text="name" item-value="name"></v-select>
          </v-col>

        </v-row>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn @click="addEvent" class="white--text" rounded color="success">Guardar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red lighten-1" text @click="dialog = false">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import {
    calendar_service
  } from '~/calendar_service'

  export default {
    props: ['applicants'],
    data() {
      return {
        time: null,
        menu2: false,
        dialog: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        item: {
          id: null,
          start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          time: '00:00',
          currentDate: null,
          name: '00:00',
          details: null,
          reminder: false,
          aplicante: null
        }
      }
    },

    created() {
      this.$parent.$on('open_dialog', data => {
        if (data) {
          this.item = data
        }
        this.dialog = true
      })
    },

    methods: {
      addEvent() {
        this.$emit('add_applicant', this.item)
        this.dialog = false
        this.item = {
          id: null,
          start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          time: '00:00',
          currentDate: null,
          name: '00:00',
          details: null,
          reminder: false,
          aplicante: null
        }
      },
      resetDialog() {
        this.item = {
          calendar_service_id: null,
          id: null,
          start: null,
          currentDate: null,
          name: '00:00'
        }
        this.dialog = false
      },

      deleteHour() {
        let data = {
          id: this.item.id
        }
        calendar_service.delete_service_hour(this.item.calendar_service_id, data).then(res => {
          this.$toast.sucs('Hora eliminada con exito')
          this.$emit('remove_hour', this.item)
          this.resetDialog()
        }, res => {
          this.$toast.error('Algo ha salido mal, por favor intente nuevamente')
        })
      },

      updateHour() {
        let data = {
          id: this.item.id,
          initTime: this.item.name,
          currentDate: this.item.currentDate
        }

        calendar_service.update_calendar_service(this.item.calendar_service_id, data).then(res => {
          this.$toast.sucs('Horario actualizado con exito')
          this.resetDialog()
        }, res => {
          this.$toast.error('Algo ha salido mal, por favor intente nuevamente')
        })
      }
    }
  }
</script>