<template>
  <v-container>


    <v-card>
      <v-toolbar flat color="blue accent-2" class="white--text">
        <v-btn to="/aplicantes" icon text>
          <v-icon small class="white--text">mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title>
          <h3 class="font-weight-light">Lista Pagos</h3>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>

        <v-row dense>

          <v-col cols="12" md="4">
            <v-combobox hide-details outlined small dense label="Asociar cliente / crear" item-text="nombre" item-value="id"></v-combobox>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field hide-details small type="number" dense outlined label="Monto $"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-file-input hide-details ref="fileinput" small dense outlined accept="image/*" label="Archivo"></v-file-input>
          </v-col>

        </v-row>

        <v-row dense class="mt-4">
          <v-col cols="12" md="4">
            <v-btn rounded class="white--text" depressed color="blue accent-2">guardar</v-btn>
            <v-btn rounded class="white--text ml-4" depressed color="success">nuevo</v-btn>
          </v-col>
        </v-row>


        <loader v-if="isloading"></loader>

        <v-row dense>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" disable-pagination hide-default-footer item-key="key" class="elevation-1 mt-6">


              <template v-slot:item.tags="{item}">
                <v-chip v-for="tag in item.tags" color="green" small dense class="white--text ma-1">{{ tag }}</v-chip>
              </template>

              <template v-slot:item.actions="{item}">


                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="editItem(item)" small class="mr-2" color="blue" v-bind="attrs" v-on="on">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="openCaseDialog(item)" small class="mr-2" color="orange" v-bind="attrs" v-on="on">
                      mdi-file-account
                    </v-icon>
                  </template>
                  <span>Crear solicitud / Caso</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="showCases(item)" small class="mr-2" color="green">
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>Ver casos</span>
                </v-tooltip>

              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

  </v-container>
</template>

<script>
  import {
    categoria_service
  } from '~/categoria_service'

  export default {

    data() {
      return {
        headers: [{
            text: 'Fecha',
            value: 'fecha',
          },
          {
            text: 'Monto',
            value: 'monto',
          },
          {
            text: 'Cliente',
            value: 'cliente',
          },
          {
            text: 'Acciones',
            value: 'action',
            sortable: false
          },
        ],
      }
    },


    methods: {
      nuevo() {
        let type = this.categoria.type
        this.$refs.fileinput.reset()
        this.$store.dispatch('setCategoria', {
          id: null,
          name_es: null,
          name_en: null,
          name_pt: null,
          image: null,
          description: null,
          description_es: null,
          description_en: null,
          description_pt: null,
          type: type,
          certificate: false,
          enableCertificate: false,
          enableDocument: false,
          enableIdCard: false,
        })
      },

      getCategories(role) {
        categoria_service.get_categorias(role).then(res => {
          this.categorias = res.data
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      updateOrCreate() {
        this.categoria.id ? this.updateCategoria() : this.saveCategoria()
      },

      updateCategoria() {
        this.axios.put(`category/${this.categoria.id}`, this.categoria).then(res => {
          this.uploadImage(res.data)
          let find = this.categorias.findIndex(x => x.id == res.data.id)
          this.$set(this.categorias, find, res.data)
          this.nuevo()
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      saveCategoria() {
        this.axios.post(`category`, this.categoria).then(res => {
          this.$toast.sucs('Categoria guardada con exito')
          this.uploadImage(res.data)
          this.categorias.push(res.data)
          this.nuevo()
        }, res => {
          this.$toast.error('Error consultando registro')
        })
      },

      deleteCategoria(item) {
        this.axios.delete(`category/${item.id}`).then(res => {
          this.$toast.sucs('Categoria eliminada con exito')
          let find = this.categorias.findIndex(x => x.id == item.id)
          this.categorias.splice(find, 1)
        }, res => {
          this.$toast.error('Error eliminando categoria')
        })
      },



      onFileChange(file) {
        this.upload.append('img', file)
      },

      setItem(item) {
        this.$store.dispatch('setCategoria', JSON.parse(JSON.stringify(item)))
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
        //this.categoria = JSON.parse(JSON.stringify(item))
      }
    },
    computed: {
      isloading() {
        return this.$store.getters.getloading
      },
      errors() {
        return this.$store.getters.geterrors
      },
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
      categoria() {
        return this.$store.getters.get_categoria
      }
    }
  }
</script>