<template>
  <v-dialog persistent v-model="dialog" width="500">

    <v-card>
      <v-card-title class="text-h5 pink darken-1 white--text">
        Editar / Guardar
      </v-card-title>

      <v-card-text class="pt-4">

        <v-form ref="form" v-model="valid" lazy-validation>

          <v-row dense>
            <v-col cols="12">
              <v-text-field v-model="item.name" :rules="required_rule" small dense outlined label="Nombre"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field v-model="item.email" :rules="email_rules" small dense outlined label="Email"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field v-model="item.phone" :rules="required_rule" small dense outlined label="Teléfono"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-combobox small-chips v-model="item.tags" label="Tags" multiple chips></v-combobox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-btn :loading="isloading" @click="updateOrCreate" rounded class="white--text" depressed color="pink darken-1">guardar</v-btn>
            </v-col>
          </v-row>


        </v-form>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn color="red lighten-1" text @click="closeDialog">
          Cerrar
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
  import {
    aplicant_rules
  } from '../mixins/aplicant_rules'

  import {
    applicant_service
  } from '~/applicant_service'

  export default {
    mixins: [aplicant_rules],
    data() {
      return {
        dialog: false,

        item: {
          name: null,
          email: null,
          phone: null,
          tags: []
        }
      }
    },

    created() {
      this.$parent.$on('open_dialog', data => {
        if (data) {
          this.item = JSON.parse(JSON.stringify(data))
        }
        this.dialog = true
      })
    },

    methods: {
      updateOrCreate() {
        if (!this.$refs.form.validate()) {
          return this.$toast.warn('Error de validando formulario')
        }
        this.item.id ? this.update() : this.save()
      },

      save() {
        applicant_service.save_applicant(this.item).then(res => {
          this.$toast.sucs('Aplicante guardado con exito')
          this.$emit('update_table', res.data)
          this.closeDialog()
        }, res => {
          this.$toast.error('Error guardando aplicante')
        })
      },

      update() {
        applicant_service.update_applicant(this.item.id, this.item).then(res => {
          this.$toast.sucs('Aplicante actualizado con exito')
          this.$emit('update_table', res.data)
          this.closeDialog()
        }, res => {
          this.$toast.error('Error guardando aplicante')
        })
      },

      closeDialog() {
        this.dialog = false
        this.$refs.form.resetValidation()
        this.item = {
          name: null,
          email: null,
          phone: null,
          tags: []
        }
      }
    },

    computed: {
      isloading() {
        return this.$store.getters.getloading
      }
    }
  }
</script>