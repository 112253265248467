<template>
  <v-list id="nav_list" dense nav>

    <v-list-item :to="{path: '/'}">
      <v-list-item-icon>
        <v-icon color="white">mdi-home</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Inicio</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-group v-for="item in computedheaders" v-model="item.active" :key="item.text" no-action>
      <template v-slot:activator>
        <v-list-item-icon>
          <v-icon color="white">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title color="white" v-html="item.text"></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item v-for="child in item.childs" :key="child.text" :to="{path: child.path}">
        <v-list-item-content>
          <v-list-item-title class="pl-7 " v-html="child.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

  </v-list>
</template>

<script>
  export default {
    data() {
      return {
        items: [{
            icon: 'mdi-account-group',
            text: 'Usuarios',
            user: ['ADMIN_ROLE'],
            active: false,
            childs: [{
                path: '/lista-usuarios',
                icon: 'mdi-account-box',
                text: 'Lista usuarios',
                user: ['ADMIN_ROLE']
              },
              {
                path: '/actualizar-password',
                icon: 'mdi-account-box',
                text: 'Cambiar contraseña',
                user: ['ADMIN_ROLE']
              },
            ]
          },
          {
            icon: 'mdi-cellphone-android',
            text: 'Servicios <br> en la App',
            user: ['CONSULTANT_ROLE'],
            active: false,
            childs: [{
                path: '/servicios',
                text: 'Crea tu perfil',
              },
              {
                path: '/aplicantes',
                text: 'Panel legal',
              },
              {
                path: '/mis-bookings',
                text: 'Citas',
              },
              {
                path: '/lista-chats',
                text: 'Chats',
              },
              {
                path: '/leads-calificados',
                text: 'Clientes calificados',
              },
              {
                path: '/lista-clientes-potenciales',
                text: 'Clientes potenciales',
              }
            ],
          },

          {
            icon: 'mdi-monitor-cellphone',
            text: 'Servicios <br> en la web',
            user: ['CONSULTANT_ROLE'],
            active: false,
            childs: [{
              path: '/mis-anuncios',
              text: 'Directorio',
            }, ],
          },

          {
            icon: 'mdi-calendar',
            text: 'Agenda',
            user: ['ADMIN_ROLE', 'VIGILANTE'],
            active: false,
            childs: [{
              path: '/agenda',
              icon: 'mdi-monitor-lock',
              text: 'Calendario',
              user: ['ADMIN_ROLE']
            }, ]
          },

          {
            icon: 'mdi-file-document-box-multiple',
            text: 'Procedimientos',
            user: ['ADMIN_ROLE', 'VIGILANTE'],
            active: false,
            childs: [{
              path: '/lista-procedimientos',
              icon: 'mdi-monitor-lock',
              text: 'Listado',
              user: ['VIGILANTE']
            }, ]
          },

          {
            icon: 'mdi-lock',
            text: 'Accesos',
            user: ['ADMIN_ROLE', 'VIGILANTE'],
            active: false,
            childs: [{
              path: '/lista-accesos',
              icon: 'mdi-lock',
              text: 'Accesos',
              user: ['ADMIN_ROLE', 'VIGILANTE']
            }, ]
          },

          {
            icon: 'mdi-bell-alert',
            text: 'Novedades',
            user: ['ADMIN_ROLE', 'VIGILANTE'],
            active: false,
            childs: [{
              path: '/lista-novedades',
              icon: 'mdi-bell-alert',
              text: 'Listado',
              user: ['ADMIN_ROLE', 'VIGILANTE']
            }, ]
          },
        ]
      }
    },

    methods: {
      getFeatures() {
        let booking = this.$store.getters.getpackage
        let paquete = booking.packageDirectoryId
        let features = []
        if (booking && paquete && paquete.businessCard) {
          features.push('business_enable')
        }
        return features
      }
    },
    computed: {
      role() {
        let user = this.$store.getters.getuser
        return user.role
      },

      computedheaders() {
        if (this.role != null) {
          let features = this.getFeatures()
          return this.items.filter(x => {
            return x.user.some(user => user == this.role) || x.user.some(user => features.includes(user))
          })
        }
        return []
      }
    }
  }
</script>

<style media="screen">
  .v-list-item--dense .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  .v-list--dense .v-list-item .v-list-item__title,
  .v-list--dense .v-list-item .v-list-item__subtitle {
    line-height: 1.4rem !important;
  }

  .v-list-item--dense .v-list-item__content,
  .v-list--dense .v-list-item .v-list-item__content {
    padding: 8px 0 !important;
  }

  .v-list-item--dense,
  .v-list--dense .v-list-item {
    min-height: 10px !important;
  }

  .v-list .v-list-item--active {
    color: #fff;
  }

  .v-list-group--active>.v-list-group__header .v-list-item,
  .v-list-group--active>.v-list-group__header .v-list-item__content,
  .v-list-group--active>.v-list-group__header .v-list-group__header__prepend-icon .v-icon {
    color: #fff;
  }

  .v-list-group--active>.v-list-group__header .v-list-item,
  .v-list-group--active>.v-list-group__header .v-list-item__content,
  .v-list-group--active>.v-list-group__header .v-list-group__header__prepend-icon .v-icon {
    color: white !important;
  }

  .v-list .v-list-item--active {
    color: white !important;
  }

  #nav_list .v-list-item--dense .v-list-item__content,
  .v-list--dense .v-list-item .v-list-item__content {
    /*padding: 3px 0 !important;*/
  }
</style>